<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-2 mr-2 mt-2">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Status</p>
          <template v-for="(item, i) in statusItems">
            <v-checkbox
              color="#9E8819"
              :key="i"
              v-model="statusSelected"
              :label="item.text"
              :value="item.value"
              class="mt-0 filterContent body-regular"
              hide-details
            ></v-checkbox>
          </template>
        </div>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapActions} from 'vuex'
import FilterButton from '@/components/FilterButton'

import {symptomaticAreasStatus, symptomaticAreasStatusEnum} from '@/constants/symptomatic-areas'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    statusSelected: [],
    countFiltered: 0,
    isChanged: false,
    appliedStatus: [],
  }),
  components: {
    FilterButton,
  },
  computed: {
    statusItems() {
      const items = []
      Object.keys(symptomaticAreasStatusEnum).forEach(key =>
        items.push({
          text: symptomaticAreasStatus[symptomaticAreasStatusEnum[key]],
          value: symptomaticAreasStatusEnum[key]
        })
      )
      return items
    },
    isClearFilter() {
      const changedStatus = !_.isEqual(this.statusSelected, [])
      return changedStatus
    },
    isChangeFilter() {
      const changedStatus = !_.isEqual(this.statusSelected, this.appliedStatus)
      return changedStatus
    },
  },
  methods: {
    sumFiltered() {
      const sumStatus = this.statusSelected.length > 0 ? 1 : 0
      return sumStatus
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedStatus = this.statusSelected
      this.setFilterStatus(this.statusSelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      this.statusSelected = []
      this.isChanged = true
    },
    handleCancelFilter() {
      // wait for multiple select render
      this.$nextTick(() => {
        this.statusSelected = this.appliedStatus
        this.setFilterStatus(this.statusSelected)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('symptomaticAreas', ['fetch', 'setFilterStatus'])
  }
}
</script>
